@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme-vars($theme, $link-color) {
  --link-color: #{$link-color};
  --warn-color: #{mat.get-color-from-palette(map-get($theme, warn))};

  --ngx-json-string: black; // color of string values
  --ngx-json-number: black; // color of number values
  --ngx-json-boolean: black; // color of boolean values
  --ngx-json-date: black; // color of date values
  // --ngx-json-array: ; // color of array values
  // --ngx-json-object: ; // color of object values
  // --ngx-json-function: ; // color of function values
  --ngx-json-null: black; // color of null values
  --ngx-json-null-bg: transparent; // background color of null values
  // --ngx-json-undefined: ; // color of undefined values
  // --ngx-json-toggler: ; // color of toggler
  --ngx-json-key: #002562; // color of keys
  // --ngx-json-separator: ; // color of separators
  // --ngx-json-value: ; // color of values
  // --ngx-json-undefined-key: ; // color for key of undefined values
  // --ngx-json-font-family: ; // font-family
  // --ngx-json-font-size: ; // font-size
}
