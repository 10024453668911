@forward 'theme';
@forward 'tailwind';
html,
body {
  height: 100%;
}
body {
  margin: 0;
}
.snackbar-info-style {
  background-color: orange;
  color: black;
}
.snackbar-error-style {
  background-color: crimson;
  color: black;
}
.code {
  font-family: monospace;
  font-size: larger;
}
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.mat-hint-text-ellipsis mat-hint {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
.asset-card {
  width: 300px;
}
.list-style-material-icons-check {
  list-style-type: none;
  padding-inline-start: 8px;
}
.list-style-material-icons-check li {
    margin-top: 8px;
  }
.list-style-material-icons-check li:before {
      margin-right: 8px;
      content: 'check';
      font-family: 'Material Icons';
    }
.form-section-title {
  margin: 16px 3px;
  text-transform: uppercase;
  color: #373737;
  font-size: 14px;
  letter-spacing: 0.04em;
}
.property-grid-group-title {
  margin: 30px 3px 15px 3px;
  text-transform: uppercase;
  color: #373737;
  font-size: 14px;
  letter-spacing: 0.05em;
}
.mat-form-field-any-size-hint .mat-form-field-subscript-wrapper {
  position: unset !important;
}
// hrefs
.link {
  color: var(--link-color);
  text-decoration: none;
}
.link:hover,
  .link:focus {
    text-decoration: underline;
  }
.flex-even-sized {
  flex: 1 1 0;
}
.text-slate {
  color: rgba(0, 0, 0, 0.54);
}
.text-warn {
  color: var(--warn-color);
}
.bg-muted {
  background: rgba(0, 0, 0, 0.12);
}
.text-sm {
  font-size: 0.8em;
}
.mat-icon-\[80px\] {
  font-size: 80px !important;
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
.mat-icon-\[40px\] {
  font-size: 40px !important;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}
.mat-icon-\[10px\] {
  font-size: 10px !important;
  width: 10px !important;
  height: 10px !important;
  line-height: 10px !important;
}
.mat-icon-\[14px\] {
  margin-right: 5px;
  font-size: 14px !important;
  width: 14px !important;
  height: 14px !important;
  line-height: 14px !important;
}
.mat-icon-\[16px\] {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
}
.mat-icon-\[12px\] {
  font-size: 12px !important;
  width: 12px !important;
  height: 12px !important;
  line-height: 12px !important;
}
.mat-icon-\[18px\] {
  margin-top: 5px;
  font-size: 18px !important;
  width: 18px !important;
  height: 18px !important;
  line-height: 18px !important;
}
.mat-icon-\[22px\] {
  font-size: 22px !important;
  width: 22px !important;
  height: 22px !important;
  line-height: 22px !important;
}
.mat-icon-\[24px\] {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
  line-height: 24px !important;
}
.mat-icon-\[28px\] {
  margin-top: 8px;
  font-size: 28px !important;
  width: 28px !important;
  height: 28px !important;
  line-height: 28px !important;
}
.mat-icon-\[17px\] {
  font-size: 17px !important;
  line-height: 17px !important;
  width: 17px !important;
  height: 17px !important;
}
.mat-card-header-text {
  overflow-wrap: break-word;
  overflow: hidden;
}
.mat-expansion-panel-flex-col .mat-expansion-panel-body {
  display: flex;
  flex-direction: column;
}
.mat-form-field-without-margin-bottom .mat-form-field-wrapper {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding-bottom: 0 !important;
  }
.mat-form-field-without-margin-bottom .mat-form-field-subscript-wrapper {
    top: unset !important;
  }
.max-two-lines-list-item .mat-list-text {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden !important;
}
.mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden !important;
}
.markdown-description{
  color: var(--tw-prose-body);
  max-width: 65ch;
}
.markdown-description :where(p):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}
.markdown-description :where([class~="lead"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}
.markdown-description :where(a):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-links);
  text-decoration: underline;
  font-weight: 500;
}
.markdown-description :where(strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-bold);
  font-weight: 600;
}
.markdown-description :where(a strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.markdown-description :where(blockquote strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.markdown-description :where(thead th strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.markdown-description :where(ol):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: decimal;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
}
.markdown-description :where(ol[type="A"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: upper-alpha;
}
.markdown-description :where(ol[type="a"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: lower-alpha;
}
.markdown-description :where(ol[type="A" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: upper-alpha;
}
.markdown-description :where(ol[type="a" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: lower-alpha;
}
.markdown-description :where(ol[type="I"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: upper-roman;
}
.markdown-description :where(ol[type="i"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: lower-roman;
}
.markdown-description :where(ol[type="I" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: upper-roman;
}
.markdown-description :where(ol[type="i" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: lower-roman;
}
.markdown-description :where(ol[type="1"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: decimal;
}
.markdown-description :where(ul):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: disc;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
}
.markdown-description :where(ol > li):not(:where([class~="not-prose"],[class~="not-prose"] *))::marker{
  font-weight: 400;
  color: var(--tw-prose-counters);
}
.markdown-description :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *))::marker{
  color: var(--tw-prose-bullets);
}
.markdown-description :where(dt):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.25em;
}
.markdown-description :where(hr):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}
.markdown-description :where(blockquote):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-weight: 500;
  font-style: italic;
  color: var(--tw-prose-quotes);
  border-left-width: 0.25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "\201C""\201D""\2018""\2019";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
}
.markdown-description :where(blockquote p:first-of-type):not(:where([class~="not-prose"],[class~="not-prose"] *))::before{
  content: open-quote;
}
.markdown-description :where(blockquote p:last-of-type):not(:where([class~="not-prose"],[class~="not-prose"] *))::after{
  content: close-quote;
}
.markdown-description :where(h1):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-headings);
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}
.markdown-description :where(h1 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-weight: 900;
  color: inherit;
}
.markdown-description :where(h2):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-headings);
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}
.markdown-description :where(h2 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-weight: 800;
  color: inherit;
}
.markdown-description :where(h3):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-headings);
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}
.markdown-description :where(h3 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-weight: 700;
  color: inherit;
}
.markdown-description :where(h4):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}
.markdown-description :where(h4 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-weight: 700;
  color: inherit;
}
.markdown-description :where(img):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 2em;
  margin-bottom: 2em;
}
.markdown-description :where(picture):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  display: block;
  margin-top: 2em;
  margin-bottom: 2em;
}
.markdown-description :where(kbd):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-weight: 500;
  font-family: inherit;
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  font-size: 0.875em;
  border-radius: 0.3125rem;
  padding-top: 0.1875em;
  padding-right: 0.375em;
  padding-bottom: 0.1875em;
  padding-left: 0.375em;
}
.markdown-description :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-code);
  font-weight: 600;
  font-size: 0.875em;
}
.markdown-description :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *))::before{
  content: "`";
}
.markdown-description :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *))::after{
  content: "`";
}
.markdown-description :where(a code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.markdown-description :where(h1 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.markdown-description :where(h2 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
  font-size: 0.875em;
}
.markdown-description :where(h3 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
  font-size: 0.9em;
}
.markdown-description :where(h4 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.markdown-description :where(blockquote code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.markdown-description :where(thead th code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.markdown-description :where(pre):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  overflow-x: auto;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding-top: 0.8571429em;
  padding-right: 1.1428571em;
  padding-bottom: 0.8571429em;
  padding-left: 1.1428571em;
}
.markdown-description :where(pre code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
.markdown-description :where(pre code):not(:where([class~="not-prose"],[class~="not-prose"] *))::before{
  content: none;
}
.markdown-description :where(pre code):not(:where([class~="not-prose"],[class~="not-prose"] *))::after{
  content: none;
}
.markdown-description :where(table):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}
.markdown-description :where(thead):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}
.markdown-description :where(thead th):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-headings);
  font-weight: 600;
  vertical-align: bottom;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}
.markdown-description :where(tbody tr):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}
.markdown-description :where(tbody tr:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  border-bottom-width: 0;
}
.markdown-description :where(tbody td):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  vertical-align: baseline;
}
.markdown-description :where(tfoot):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}
.markdown-description :where(tfoot td):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  vertical-align: top;
}
.markdown-description :where(figure > *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
  margin-bottom: 0;
}
.markdown-description :where(figcaption):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-captions);
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}
.markdown-description{
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}
.markdown-description :where(picture > img):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
  margin-bottom: 0;
}
.markdown-description :where(video):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 2em;
  margin-bottom: 2em;
}
.markdown-description :where(li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.markdown-description :where(ol > li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-left: 0.375em;
}
.markdown-description :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-left: 0.375em;
}
.markdown-description :where(.prose > ul > li p):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.markdown-description :where(.prose > ul > li > *:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.25em;
}
.markdown-description :where(.prose > ul > li > *:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-bottom: 1.25em;
}
.markdown-description :where(.prose > ol > li > *:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.25em;
}
.markdown-description :where(.prose > ol > li > *:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-bottom: 1.25em;
}
.markdown-description :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.markdown-description :where(dl):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}
.markdown-description :where(dd):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0.5em;
  padding-left: 1.625em;
}
.markdown-description :where(hr + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.markdown-description :where(h2 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.markdown-description :where(h3 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.markdown-description :where(h4 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.markdown-description :where(thead th:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-left: 0;
}
.markdown-description :where(thead th:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-right: 0;
}
.markdown-description :where(tbody td, tfoot td):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-top: 0.5714286em;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}
.markdown-description :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-left: 0;
}
.markdown-description :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-right: 0;
}
.markdown-description :where(figure):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 2em;
  margin-bottom: 2em;
}
.markdown-description :where(.prose > :first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.markdown-description :where(.prose > :last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-bottom: 0;
}
.markdown-description{
  max-width: none;
  white-space: normal;
}
.markdown-description :is(:where(img):not(:where([class~="not-prose"],[class~="not-prose"] *))){
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  max-height: 250px;
  max-width: 100%;
  border-radius: 0.5rem;
  object-fit: contain;
}
.markdown-description :is(:where(a):not(:where([class~="not-prose"],[class~="not-prose"] *))){
  color: var(--link-color);
}
.markdown-description :is(:where(blockquote):not(:where([class~="not-prose"],[class~="not-prose"] *))){
  margin-left: 0px;
  margin-right: 0px;
}
.markdown-description :is(:where(code):not(:where([class~="not-prose"],[class~="not-prose"] *))){
  border-radius: 0.5rem;
}
.markdown-description :is(:where(blockquote):not(:where([class~="not-prose"],[class~="not-prose"] *))){
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-style: solid;
}
.markdown-description :is(:where(table):not(:where([class~="not-prose"],[class~="not-prose"] *))){
  border-collapse: collapse;
  font-size: 13px;
}
.markdown-description :is(:where(th):not(:where([class~="not-prose"],[class~="not-prose"] *))){
  border-width: 1px;
  border-style: solid;
  border-color: var(--tw-prose-td-borders);
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 6px;
  padding-bottom: 6px;
  vertical-align: middle;
}
.markdown-description :is(:where(td):not(:where([class~="not-prose"],[class~="not-prose"] *))){
  border-width: 1px;
  border-style: solid;
  border-color: var(--tw-prose-td-borders);
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 6px;
  padding-bottom: 6px;
  vertical-align: middle;
}
.markdown-description {

  font: 400 14px / 20px Sans;
  letter-spacing: normal;
}
.markdown-description h1,
  .markdown-description h2,
  .markdown-description h3,
  .markdown-description h4,
  .markdown-description h5 {
    font-weight: 500;
  }
.markdown-description h1 {
    font-size: 19px;
  }
.markdown-description h2 {
    font-size: 17px;
  }
.markdown-description h3 {
    font-size: 15px;
  }
.markdown-description h4 {
    font-size: 14px;
  }
.markdown-description h5 {
    font-size: 12px;
  }
.markdown-description pre code{
  padding: 0px;
    border: initial;
    line-height: 1.5;
}
.markdown-description a:has(> img){
  width: -moz-fit-content;
  width: fit-content;
}
.markdown-description p:has(> a > img){
  display: flex;
  flex-direction: column;
}
.markdown-description :last-child {
    margin: 0px;
  }
.mat-menu-content {
  padding: 0 !important;
}
.mat-menu-panel {
  min-height: 0 !important;
}
.mat-icon-button:hover {
    background: #ededed !important;
  }
.mat-icon-button mat-icon {
    margin-top: -1px;
  }
#edit-asset-form .mat-form-field-infix {
  border-top: 4.8px solid transparent !important;
}
.normal-form-field .mat-form-field-infix {
  border-top: 13.6px solid transparent !important;
}
#edit-asset-form .mat-form-field-subscript-wrapper {
  font-size: 100%;
}
#edit-asset-form .mat-hint {
  font-size: 0.75rem;
}
